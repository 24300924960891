
import { Faqs } from "../models/faqs.model";
import { allApis } from "./allApis";

export const faqsApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getfqas: builder.query<Faqs[], void>({
      query: () => 'faqs',
      
    }),
    
  }),
});
export const {useGetfqasQuery} = faqsApi;

