/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {VideoMeeting} from './videoMeeting.model'
import { Participant, Track, TrackPublication } from 'livekit-client'

export enum UserType {
  Host = 'Host',
  CoHost = 'CoHost',
  Participant = 'Participant',
}

export enum LiveKitEventsType {
  CONNECTED,
  DISCONNECTED,
  ERROR,

  CONFERENCE_JOINED,
  CONFERENCE_LEFT,

  // Local
  LOCAL_TRACK_MUTE_CHANGED,
  LOCAL_TRACK_VIDEO_MUTE_CHANGED,
  LOCAL_TRACK_AUDIO_OUTPUT_CHANGED,

  // Remote User
  USER_JOINED,
  USER_LEFT,

  // Remote
  REMOTE_TRACK_MUTE_CHANGED,

  // Socket
  RECEIVED_USER_INFO,
  RECEIVED_HAND_RAISED,
  LOCAL_SOCKET_ROOM_JOINED,
  LOCAL_SOCKET_ROOM_LEFT,
  REMOTE_SOCKET_ROOM_JOINED,
  RECEIVED_END_FOR_ALL,
  RECEIVED_MUTE_ALL,
  UNMUTE_ALLOWED,
  RECEIVED_MUTE_ONE,
  RECEIVED_UNMUTE_ALLOWED,
  REMOVE_ONE,

  // Chat Message
  RECEIVED_CHAT_MESSAGE,
}

export interface LiveKitEvent {
  type: LiveKitEventsType
  data?: LiveKitCommand | LiveKitChat | LiveKitUser | string
}

export interface LiveKitCommand {
  participantId: string
  meetingId: string
  command: CommandTypes
  params: any
}

export enum CommandTypes {
  MUTE_ALL = 'mute_all',
  UNMUTE_ALL = 'unmute_all',
  UNMUTE_ALLOWED = 'unmute_allowed',
  END_FOR_ALL = 'end_for_all',
  USER_INFO = 'user_info',
  USER_INFO_REQUEST = 'user_info_request',
  MUTE_ONE = 'mute_one',
  CHAT_MESSAGE = 'chat_message',
  HAND_RAISE = 'hand_raise',
}

export interface LiveKitUser {
  participantId: string
  displayName: string
  avatar: string
  userId: string
  isMuted: boolean
  isVideoMuted: boolean
  isScreenShared: boolean
  isHandRaised: boolean
  userType: UserType
  isAllowedUnmute: boolean
  isJoinedAnonymous?: boolean
}

export interface LiveKitChat {
  chatId: string
  userName: string
  meetingId: string
  senderUserId: string
  text: string
  dateTimeUtc: string
  unread?: boolean
}

export interface ConnectParamsType {
  url: string
  token: string
  videoEnabled: boolean
  audioEnabled: boolean
  screenShareEnabled: boolean
  meetingId: string
  meetingTitle: string
  audioDeviceId?: string
  videoDeviceId?: string
}

export interface MetaDataType {
  profileImageUrl: string
  displayName: string
  userId: string
  userType: UserType
  avatar: string | undefined
}

export interface LiveKitReducerInitType {
  meetingInfo: VideoMeeting
  connectParams: ConnectParamsType
  isMeetingConnected: boolean
  isMeetingJoined: boolean
  showMeetingGuide: boolean
  isMeetingMinimized: boolean
  showMeeting: boolean
  isMeetingChatOpen: boolean
  showEndConfirmation: boolean
  videoChatFullScreen: boolean
  currentMeetingUser: LiveKitUser
  remoteMeetingUsers: {[key: string]: LiveKitUser}
  // isAddedToGroup: boolean
  liveKitChats: LiveKitChat[]
  unreadMeetingChat: boolean
  isMeetingEnded: boolean
  pinnedParticipantId: string | undefined
  showStats: boolean
  showAllowedUnmuteToast: boolean
  joinTimeError: string
  isAnonymous: boolean
  displayName: string
}

export interface LiveKitCommand {
  participantId: string
  meetingId: string
  command: CommandTypes
  params: any
}

export interface LiveKitHandRaised {
  handRaised: boolean
}

export interface LiveKitEndAllowUnmute {
  allowUnmute: boolean
  participantId: string
}
export interface LiveKitEndAllowMute {
  allowMute: boolean
  participantId: string
}

export interface CustomTrack {
  participant: Participant
  publication: TrackPublication
  source: Track.Source
}

export interface StagedParticipantsMessage {
  stagedParticipants: string[]
}

export interface LiveKitEndRoom {
  currentUserId: string
}

export interface ChatSendMessage {
  msg: any;
  id: string;
  media?: string;
  mediaType?: string;
}
export interface Message {
  id: string
  from: string
  message: string
  action: 'SENT' | 'RECEIVED'
  time: Date
  media:string
  mediaType: string
}
