/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import {  useNavigate } from 'react-router-dom';
import Icon from '../../Components/base/icon/icon';
import { Button } from '../../Components';
import { useTermsAndConditionsHooks } from '../../Hooks/TermsAndConditionsHook';
import Footer from '../Footer';
import { useAppSelector } from '../../Store/hooks';
import { getCurrentUser } from '../../Services/userReducer';
import {
  changeTermsAndCondition,
  getIsSignUpTermsAccepted,
} from '../../Services/signUpReducer';
import { useDispatch } from 'react-redux';
import { useUpdateUserTermsPolicyMutation } from '../../Services/userPolicyAgreementsApi';
import {  useEffect, useRef, useState } from 'react';
import { toastSuccess } from '../../Components/toast';
import Header from '../Header';
import Field from '../../Components/base/field/field';
import { getToken } from '../../Services/tokenReducer';
import {
  Accordion,
  AccordionItem,
} from '../../Components/base/accordion/accordion';
import { EventCard } from '../EventsCard/EventCard';
import { format } from 'date-fns';
import NewIcon from '../../Components/base/icon/newIcons';
import beemg_arrow from '../../Assets/icons/beemg-arrow.svg';

/**
 * A component to display the terms and conditions of the application.
 * It retrieves the terms and conditions from the server and displays them
 * in a page with a print button.
 * If the user is not logged in, it will also display a cancel button.
 * If the user is logged in, it will also display a accept button.
 * If the user is not logged in and the terms and conditions have not been accepted,
 * it will display a accept button and a cancel button.
 * If the user is logged in and the terms and conditions have not been accepted,
 * it will display a accept button and a cancel button.
 * When the user clicks the accept button, it will update the user's terms and conditions
 * status in the server.
 * When the user clicks the cancel button, it will navigate to the login page.
 * When the user clicks the print button, it will print the terms and conditions.
 * @returns A JSX element to display the terms and conditions.
 */
function TermsAndConditions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userAccessToken = useAppSelector(getToken);
  const currentUser = useAppSelector(getCurrentUser);
  const {
    hidebutton,
    policies,
    navigateScreen,
    filteredPolicies: activePolicyData,
  } = useTermsAndConditionsHooks();
  const [
    agreeTermsAndPolicies,
    { isSuccess: userPolicyAgreed, isLoading: userPolicyLoading },
  ] = useUpdateUserTermsPolicyMutation();
  const termsAndConditionStatus = useAppSelector(getIsSignUpTermsAccepted);
  const [toasterView, setToasterView] = useState(false);
  const accordionRef = useRef();
  const [checkboxes, setCheckboxes] = useState<boolean[]>(
    activePolicyData ? new Array(activePolicyData.length).fill(false) : [],
  );
  const [isChecked, setIsChecked] = useState(false); // Track if all checkboxes are checked
  const [searchTerm, setSearchTerm] = useState('');

  const [searchResults, setSearchResults] = useState<number[]>([]); // Store the indices of matching policies
  const policyRefs = useRef<HTMLDivElement[]>([]); // Refs for policy items

  const handleSearch = (term: string) => {
    setSearchTerm(term);

    if (!term) {
      // Clear highlights when search input is empty
      setSearchResults([]);
      return;
    }

    // Find indices of matching policies
    const results = activePolicyData
      ?.map((policy, index) => {
        const isMatch =
          policy.policyName.toLowerCase().includes(term.toLowerCase()) ||
          policy.description.toLowerCase().includes(term.toLowerCase());
        return isMatch ? index : null;
      })
      .filter((index) => index !== null) as number[];

    setSearchResults(results);

    // Scroll to the first result, if any
    if (results.length > 0 && policyRefs.current[results[0]]) {
      policyRefs.current[results[0]].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = checked; // Update the specific checkbox
    setCheckboxes(newCheckboxes);
    // Check if all checkboxes are true
    const allChecked = newCheckboxes.every((checkbox) => checkbox === true);
    if (allChecked && newCheckboxes.length === activePolicyData?.length) {
      setIsChecked(allChecked); // Update the isChecked state
    } else {
      setIsChecked(false); // Reset the isChecked state
    }
  };

  const printContent = (contentSelector: string, customStyles?: string) => {
    const content = document.querySelector(contentSelector);
    if (!content) {
      console.error('Content not found');
      return;
    }
  
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Content</title>
            <style>
              body {
                font-family: 'Sora', sans-serif;
                margin: 20px;
                line-height: 1.6;
              }
              ul, ol {
                list-style: none !important;
              }
              .list-inherited ul, .list-inherited ol {
                all: revert !important;
              }
              ${customStyles || ''}
            </style>
          </head>
          <body>
            <div class="list-inherited">
              ${content.innerHTML}
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };
  
  // Wrapper functions for specific use cases
  const printMainContent = () => {
    printContent(`[data-content-index="mainContent"]`);
  };
  
  const printAccordionContent = (index: number) => {
    printContent(`[data-content-index="${index}"]`);
  };

  if (navigateScreen === 'NAVIGATE_TO_LOGIN') {
    navigate('/signIn');
  }

  /**
   * Print the terms and conditions page.
   * This function sets `showPrintIcon` to false and then calls the `window.print()` method.
   * It also sets up an event listener to reset `showPrintIcon` to true when the print event is finished.
   * This is necessary because the print icon is hidden while the print dialog is open.
   */

  const [subTermsContentVisible, setSubTermsContentVisible] = useState<boolean[]>([]);

  // Synchronize visibility state with policies data
  useEffect(() => {
    if (activePolicyData && activePolicyData.length > 0) {
      // Initialize all visibility states to false
      setSubTermsContentVisible(new Array(activePolicyData.length).fill(false));
    }
  }, [activePolicyData]);

  const togglePolicyDescription = (index: number) => {
    setSubTermsContentVisible(prev =>
      prev.map((visible, i) => (i === index ? !visible : visible)),
    );
  };

  useEffect(() => {
    if (termsAndConditionStatus && toasterView) {
      toastSuccess(t('termsAndConditionsAcceptedSuccessfully'));
      navigate('/signUp');
    }
  }, [termsAndConditionStatus, toasterView]);

  useEffect(() => {
    if (userPolicyAgreed) {
      dispatch(changeTermsAndCondition(true));
      toastSuccess(t('termsAndConditionsUpdatedSuccessfully'));
      navigate('/signIn');
    }
  }, [userPolicyAgreed, dispatch, t, navigate]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col h-screen">
        <div
          className={`fixed ${currentUser ? "top-25" : "top-0"
            } left-0 w-full z-20 shadow-md px-2 py-0`}
        >
          {!currentUser && <Header />}
          <div
            className={`fixed top-20 left-0 w-full z-20 px-2 py-0 overflow-auto bg-white`}
          >
            {/* Title and Search Field */}
            <div className="flex flex-wrap items-center justify-between w-full mt-0">
              <div id="txt_termsAndConditions" className="text-xl md:text-base font-semibold flex-grow text-center ml-0 md:ml-60">
                {t('termsAndConditions')}
              </div>
              <div className="w-full md:w-64 mt-2 md:mt-0 mr-0">
                <Field
                  name="policyName"
                  type="text"
                  data-testid="searchByName"
                  placeholder={t("searchByName")}
                  icon={<NewIcon icon="SEARCH_GLASS" stroke="#535353" />}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSearch(e.target.value)
                  }
                />
              </div>
            </div>

            {/* Last Updated Section */}
            <div className="flex flex-col items-center justify-center">
              <p id="txt_lastUpdated" className="text-base">
                {t('lastUpdatedEffectiveDate')}{": "}
                {policies?.updatedAt &&
                  format(new Date(policies.updatedAt), "dd-MMMM-yyyy")}
              </p>
              <p className="text-base">
                {t('version')}: {policies?.policyVersion}
              </p>
            </div>

            {/* Marketplace Agreement */}
            <div className="text-center font-semibold text-xs mt-1 px-4">
              {t('theSiteIsAMarketplaceForServicesAndByUsingItYouAgreeToTheseTermsAndConditions')}  
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`flex-grow flex-1 overflow-y-auto ${currentUser ? "mt-20" : "mt-40" // Adjust margin-top based on currentUser
          } overflow-x-hidden min-h-[85vh] list-inherited`}
      >
        <div className="w-full flex flex-col items-center overflow-y-auto overflow-x-hidden justify-center">
          <div className="mb-8 gap-2 w-full lg:w-9/12 flex flex-col place-items-center">
            <div className="w-full mt-10 items-center">
              <div className="flex flex-col">
                {/* Policy Description */}
                <div
                  id="policy-content-scroll"
                  className="relative max-h-80 overflow-y-auto overflow-x-hidden bg-white border-r-0 mx-[5%] lg:mx-[10%]"
                >
                  {/* Main Content Print Icon */}
                  <div className="absolute top-4 right-4 z-10">
                    <button
                      className="p-2 rounded focus:outline-none hover:bg-gray-200"
                      onClick={() => printMainContent()}
                    >
                      <Icon icon="PRINT" size="small" stroke="#535353" />
                    </button>
                  </div>
                  <div
                    data-content-index="mainContent"
                    className="flex-row mx-[4%] justify-content"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: policies ? policies?.description : "",
                      }}
                    ></span>
                  </div>
                </div>

                {/* Accordion Section */}
                <div className="mt-6 mx-[5%] lg:mx-[10%] h-auto">
                  {activePolicyData &&
                    Array.from({ length: activePolicyData.length }).map((_, index) => (
                      <Accordion key={"acc" + index}>
                        <div
                          ref={(el) => {
                            if (el) {
                              policyRefs.current[index] = el;
                            }
                          }}
                          data-testid={`policyCard-${index}`}
                          id={`policyCard-${index}`}
                          className={`border border-gray-200 rounded-2xl w-full mb-2 ${searchResults.includes(index) ? "bg-BeeMG-orange" : ""
                            }`}
                        >
                          <AccordionItem toggle={"accint" + index} color="card">
                            <EventCard keyId={index}>
                              <EventCard.Image>
                                <input
                                  type="checkbox"
                                  id="acceptTerms"
                                  name="acceptTerms"
                                  className="rounded-md"
                                  checked={checkboxes[index]}
                                  onChange={(e) =>
                                    handleCheckboxChange(index, e.target.checked)
                                  }
                                />
                              </EventCard.Image>
                              <EventCard.Title>
                                <span data-testid={`txt_title${index}`} id={`txt_title${index}`} className="text-base truncate mt-1">{activePolicyData[index].policyName}</span>
                              </EventCard.Title>
                              <EventCard.Actions>
                                <button
                                  onClick={() => togglePolicyDescription(index)}
                                  className="p-2 rounded focus:outline-none hover:bg-gray-200"
                                >
                                  <img src={beemg_arrow} alt="Beemg Logo" width={15} height={15} className={`transition-transform duration-300 ${subTermsContentVisible[index]
                                    ? "rotate-90"
                                    : "rotate-0"
                                    }`} />
                                </button>
                              </EventCard.Actions>
                            </EventCard>
                          </AccordionItem>
                          {subTermsContentVisible[index] && (
                            <div id="policy-content-scroll" className="relative max-h-60 overflow-y-auto overflow-x-hidden p-4 text-sm">
                              {/* Print Icon for Accordion Content */}
                              <button
                                className="absolute mx-[3%] text-right top-2 right-2 focus:outline-none hover:bg-gray-200"
                                onClick={() => printAccordionContent(index)}
                              >
                                <Icon icon="PRINT" size="small" stroke="#535353" />
                              </button>

                              {/* Accordion Content */}
                              <div
                                data-content-index={index}
                                className="p-4"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      activePolicyData[index]?.description || "",
                                  }}
                                ></span>
                              </div>
                            </div>
                          )}
                        </div>
                      </Accordion>
                    ))}
                  {/* Buttons Section */}
                  <div className="flex">
                    <span className="w-full flex-wrap flex gap-2 mt-3 justify-end">
                      {!hidebutton && (
                        <>
                          <Button
                            data-testid="btn_accept"
                            id="btn_acceptTerms"
                            className="w-full md:w-64 normal-case"
                            disabled={!isChecked || userPolicyLoading}
                            onClick={() => {
                              if (currentUser && userAccessToken) {
                                agreeTermsAndPolicies(currentUser);
                              } else {
                                dispatch(changeTermsAndCondition(true));
                                setToasterView(true);
                              }
                            }}
                          >
                            {t("acceptAndContinue")}
                          </Button>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!currentUser && <Footer />}
    </div>
  );
}

export default TermsAndConditions;