/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

// Component not in use
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import Footer from '../Footer'
import { useAppSelector } from '../../Store/hooks'
import { getCurrentUser } from '../../Services/userReducer'
import { getSignUpToken } from '../../Services/signUpReducer'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import Header from '../Header'
import { useGetHelpDeskVideoURLQuery, useGetIntroVideoURLForLargerScreensQuery } from '../../Services/envApi'
import { useGetfqasQuery } from '../../Services/fqasApi'


/**
 * A component to display the terms and conditions of the application.
 * It retrieves the terms and conditions from the server and displays them
 * in a page with a print button.
 * If the user is not logged in, it will also display a cancel button.
 * If the user is logged in, it will also display a accept button.
 * If the user is not logged in and the terms and conditions have not been accepted,
 * it will display a accept button and a cancel button.
 * If the user is logged in and the terms and conditions have not been accepted,
 * it will display a accept button and a cancel button.
 * When the user clicks the accept button, it will update the user's terms and conditions
 * status in the server.
 * When the user clicks the cancel button, it will navigate to the login page.
 * When the user clicks the print button, it will print the terms and conditions.
 * @returns A JSX element to display the terms and conditions.
 */
type FAQItem = {
  title: string
  content: string
};
interface Bookmark {
  title: string;
  url: string;
  tags?: string[];
  createdAt?: string;
  id?: string;
}

function HelpDesk() {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useAppSelector(getSignUpToken);
  const currentUser = useAppSelector(getCurrentUser);
  const { data: faqs, isLoading: faqsLoading } = useGetfqasQuery();

  const [selectedFAQ, setSelectedFAQ] = useState<FAQItem | null>(null);

  const transformedData = {

    rows: faqs?.map(faq => ({
      title: faq.question,
      content: faq.answer || "No content available",
    })) || [],
  };

  const handleFAQClick = (row: FAQItem) => {
    setSelectedFAQ(row); // Set the clicked FAQ as the selected one
  };
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [savedItems, setSavedItems] = useState<string[]>([]); // To save bookmarked items

  const { data: HelpDeskVideoURL } = useGetHelpDeskVideoURLQuery();
  const videoTitleMapping: { [key: string]: string } = {
    beemg_intro1: 'BeeMG Intro',
    beemg_creator1: 'BeeMG Creator',
    beemg_collaborator1: 'BeeMG Collaborator',
    beemg_engagement1: 'BeeMG Engagement',
    beemg_monetize1: 'BeeMG Monetize',
  };

  const videoEntries = HelpDeskVideoURL ? Object.entries(HelpDeskVideoURL) : [];

  const getBookmarksFromLocalStorage = (): Bookmark[] => {
    const bookmarks = localStorage.getItem('bookmarks');
    return bookmarks ? JSON.parse(bookmarks) : [];
  };

  // Function to handle bookmark click
  const handleBookmarkClick = (item: string) => {
    const newBookmark: Bookmark = {
      title: item,
      url: `https://example.com/${item}`,
      id: 'sample-item-id'
    };
    const updatedBookmarks = [...bookmarks, newBookmark];
    setBookmarks(updatedBookmarks);

    // Save to localStorage
    localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
  };

  useEffect(() => {
    const syncBookmarks = () => {
      const updatedBookmarks = getBookmarksFromLocalStorage();
      setBookmarks(updatedBookmarks);
    };
    window.addEventListener('storage', syncBookmarks);
    return () => window.removeEventListener('storage', syncBookmarks);
  }, []);

  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [selectedBookmark, setSelectedBookmark] = useState<Bookmark | null>(null);

  const handleHeaderClick = () => {
    setIsToggleOpen(!isToggleOpen); // Toggle the sidebar
  };

  return (
    <div className="flex flex-col ">
      {!currentUser && <Header />}

      <div className={`flex-grow overflow-y-auto ${currentUser ? 'h-full' : ''}`}>
        <div className="w-full flex flex-col items-center overflow-y-auto justify-center">
          <div className={`mb-8 gap-2 w-full lg:w-9/12 flex place-items-center`}>
            <div className="w-full mt-10 xs:mt-0 sm:mt-0 items-center">
              <div className="text-xl md:text-2xl font-medium flex items-center mt-2 mb-8">

                {isToggleOpen && (
                  <div className="fixed top-12 left-0 h-50% w-1/3 bg-gray-100 shadow-lg p-6 z-50 transition-all duration-300 overflow-y-auto">
                    <button
                      className="text-lg  top-4 right-4 absolute font-bold"
                      onClick={() => setIsToggleOpen(false)} // Close the sidebar
                    >
                      ✕
                    </button>

                    {/* Display content based on selected bookmark */}
                    {selectedBookmark ? (
                      <div>
                        <h2 className="text-2xl font-bold ">
                          {selectedBookmark.title || 'No Title Available'}
                        </h2>

                        {/* Displaying selected bookmark content */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedBookmark.title || '<p>No Content Available</p>',
                          }}
                          className="text-sm mb-4"
                        />

                        <a
                          href={selectedBookmark.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline mt-4 block"
                        >
                          Open Full Bookmark
                        </a>
                      </div>
                    ) : (
                      <p className="text-center text-gray-500 mt-4 justify-center">No bookmark selected.</p> // Show when no bookmark is selected
                    )}
                  </div>
                )}
                {/* BeeMG HelpDesk Section */}
                <div className="px-8 py-2 text-center rounded mx-auto">
                  {t('BeeMG HelpDesk')}
                </div>
              </div>

              {/* Video Section */}

              <div className="flex justify-between items-center gap-1 my-10">
                {videoEntries.length > 0 ? (
                  videoEntries.map(([key, videoUrl], index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center w-1/4 p-2 rounded-md"
                    >
                      <div className="bg-gray-300 w-full h-40 flex justify-center items-center rounded-md">
                        <video src={videoUrl as string} controls>
                          <source src={videoUrl as string} type="video/mp4" />
                        </video>
                      </div>
                      <p className="mt-2 text-center text-sm">  {videoTitleMapping[key + '1'] || `Video Title ${index + 1}`}</p>
                    </div>
                  ))
                ) : (
                  <p>No videos available</p>
                )}
              </div>

              <hr className='mb-4'></hr>
              <div className="flex">
                {/* Left Column: FAQ List */}

                <div className="w-1/2 p-4 max-h-60 overflow-y-auto">
                  <h2 className="text-lg font-medium mb-3">Hi {currentUser ? currentUser.firstName : ''}, what are you looking for?</h2>
                  {transformedData.rows.map((item, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer p-4 mb-4 rounded-lg bg-gray-200 ${selectedFAQ?.title === item.title ? 'bg-yellow-300' : 'bg-gray-100'
                        }  hover:bg-gray-200`}
                      onClick={() => handleFAQClick(item)} // Trigger when an FAQ is clicked
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-semibold">{item.title}</span>
                        <Icon
                          icon="RIGHT"
                          size="medium"
                          height="large"
                          stroke="#535353"
                          className="ml-2"

                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* Right Column: FAQ Details */}
                <div className="w-1/2 p-4 max-h-60 overflow-y-auto scrollbar-thumb-yellow">
                  {selectedFAQ ? (
                    <div>
                      <h2 className="text-xl font-bold px-4">
                        {selectedFAQ.title}</h2>
                      {/* <p className="px-4">{selectedFAQ.content}</p> */}
                      <p
                        className="px-4"
                        dangerouslySetInnerHTML={{
                          __html: selectedFAQ?.content || '',
                        }}
                      ></p>
                      {/* Yes/No Buttons */}
                      <div className="mt-4 text-sm font-semibold">Is it helpful?</div>
                      {/* <div className="flex flex-wrap gap-6"> */}
                      <div className="grid grid-cols-3 gap-6">


                        <div className="mt-4 flex gap-2">
                          <button className="bg-gray-300 text-black py-1 px-2 rounded">Yes</button>
                          <button className="bg-gray-300 text-black py-1 px-2 rounded">No</button>
                        </div>


                        <div className="mt-4 flex gap-1 justify-end">
                          <Icon
                            icon="RIGHT"
                            size="medium"
                            height="large"
                            stroke="#535353"

                          ></Icon>

                          <button
                            onClick={() =>
                              handleBookmarkClick(

                                selectedFAQ?.content || '' // HTML content of the FAQ
                              )
                            }
                          >
                            Add Bookmark
                          </button>
                        </div>
                        <div className="mt-4 flex gap-1 justify-end">
                          <Icon icon="RIGHT" size="medium" height="large" stroke="#535353" />
                          <button className="text-black">View Related</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-gray-500 text-center justify-center">Select a topic to view here</div>
                  )}
                </div>
              </div>
              {/* </PageTitle> */}
            </div>
          </div>
        </div>
      </div>
      {!currentUser && <Footer />}
    </div>
  )
};

export default HelpDesk


