import { register } from 'module';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../Components/base/field/field';
import NewIcon from '../Components/base/icon/newIcons';
import Loading from '../Components/base/loading/loading';
import { getCreatorServerFilter } from '../Services/creatorReducer';
import { useGetAllMeetingsQuery } from '../Services/meetingApi';
import { useAppSelector } from '../Store/hooks';
import { CreatorCard } from '../Components/Creators/CreatorCard';

const MoviesLayOut = () => {
  const { t } = useTranslation();
  const creatorServerFilter = useAppSelector(getCreatorServerFilter)
  const {data: moviesData, isLoading: moviesLoading} =
    useGetAllMeetingsQuery({type: 'movies', formattedServerFilter: creatorServerFilter});

    console.log("moviesData----", moviesData)
    if (moviesLoading) return <div>Loading...</div>;

    if (!moviesData || !moviesData.results || moviesData.results.length === 0)
        return <div className='text-center mt-2 text-lg'>{t('noMoviesFound')}</div>;

  return (
    <div className="pl-4 lg:pl-[10%]">
      <div className="flex justify-start mb-4 mt-2">
      <Field
        //   {...register('name')}
          name="name"
          type="text"
          data-testid="searchByName"
          placeholder={t('searchByName')}
          icon={<NewIcon icon="SEARCH_GLASS"  stroke='#535353'/>}
          onChange={(e: any) => {
            // setValue("name", e.target.value)
          }}
        />
      </div>
     
      {moviesData.results.map((meeting, index) => (
                <CreatorCard keyId={index} meetingDetails={meeting.meetingDetails} meeting={meeting}  eventsTitle={t('movies')}/>
            ))}

      {/* {popularMovies && popularMovies.length > 0 && (
        <MoviesLayout title={t('popularMovies')} movies={popularMovies} />
      )}
      {trendingMovies && trendingMovies.length > 0 && (
        <MoviesLayout title={t('trendingMovies')} movies={trendingMovies} />
      )}
      {topRatedMovies && topRatedMovies.length > 0 && (
        <MoviesLayout title={t('topRatedMovies')} movies={topRatedMovies} />
      )}

      {(!popularMovies.length && !trendingMovies.length && !topRatedMovies.length) && (
        <NoMovies className="justify-start pl-4 lg:pl-[10%] mb-2" />
      )} */}
    </div>
  );
};

export default MoviesLayOut;
