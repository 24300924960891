/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Avatar from '../Components/base/avatar/avatar'
import useWindowDimensions from '../Components/base/carousel/whirligig/utils'
import Icon from '../Components/base/icon/icon'
import NewIcon from '../Components/base/icon/newIcons'
import Loading from '../Components/base/loading/loading'
import { DisplayNameAsString } from '../Components/base/user-name/DisplayName'
import { PageTitle } from '../Components/pageTitle'
import { UpcomingEvents } from '../Containers/ViewEvents/UpcomingEvents'
import { useViewEventHook } from '../Hooks/ViewEvent'
import { useGetSettingValue } from '../Services/settingReducer'
import { getCurrentUser } from '../Services/userReducer'
import { useAppSelector } from '../Store/hooks'
import Share from './Share'
import CancellationDetails from './ViewEvents/CancellationDetails'
import RFMHostDetails from './ViewEvents/RFMHostDetails'
import { ReviewsViewCard } from './ViewEvents/ReviewViewCard'
import Chip from '../Components/Chip'
import { useGetFeedbackCountAndAverageQuery } from '../Services/feedbackApi'
import { trackEvent } from '../Utils/analytics';


interface CollageImageProps {
  images: any[]
  index: number,
  height?: number,
  width?: number,
  className?: string,
  onClick?: () => void
}

/**
 * A component that renders an image from the given images array.
 *
 * It expects the following props:
 * - images: an array of images
 * - index: the index of the image to render in the array
 * - height: the optional height of the image
 * - width: the optional width of the image
 *
 * The component styles the image with a rounded border and uses the image URL from the settings.
 *
 * @param {CollageImageProps} props
 * @returns {JSX.Element}
 */
const CollageImage = (props: CollageImageProps) => {
  const imageURL = useGetSettingValue('IMAGE_URL')
  const { images, index, height, width, onClick } = props;

  return (
    <div style={{ height, width }} className='rounded-md md:rounded-lg overflow-hidden' onClick={onClick}>
      <img
        className={`w-full h-full`}
        src={`${imageURL}/${images[index]?.imageName}`}
        data-testid={`image-${images[index]?.imageName}`}
        alt='collageImage'
      />
    </div>
  )
}

interface ImageFullscreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: any[];
  currentIndex: number;
  onChangeIndex: (newIndex: number) => void;
}

const ImageFullscreenModal = ({
  isOpen,
  onClose,
  images,
  currentIndex,
  onChangeIndex,
}: ImageFullscreenModalProps) => {
  const imageURL = useGetSettingValue('IMAGE_URL');

  if (!isOpen) return null;

  const nextImage = () => {
    if (currentIndex < images.length - 1) {
      onChangeIndex(currentIndex + 1);
    }
  };

  const prevImage = () => {
    if (currentIndex > 0) {
      onChangeIndex(currentIndex - 1);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center top-10">
  <button 
    onClick={onClose}
    className="absolute top-14 right-4 text-white text-3xl bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70"
    aria-label="Close"
  >
    x
  </button>

  {currentIndex > 0 && (
    <button
      onClick={prevImage}
      className="absolute left-4 text-white text-4xl transform -translate-y-1/2 top-1/2"
    >
      ‹
    </button>
  )}

  <img
    src={`${imageURL}/${images[currentIndex]?.imageName}`}
    alt="Fullscreen"
    className="max-w-full max-h-full"
  />

  {currentIndex < images.length - 2 && (
    <button
      onClick={nextImage}
      className="absolute right-4 text-white text-4xl transform -translate-y-1/2 top-1/2"
    >
      ›
    </button>
  )}
</div>

  );
};


/**
 * A component that renders a collage of images and/or a video.
 *
 * It takes the following props:
 * - image: an array of images
 * - video: an optional video
 *
 * The component renders the images and/or video in a collage layout that is responsive to the screen size.
 *
 * The component uses the image URL from the settings.
 *
 * @param {object} props - The props of the component.
 * @param {any[]} props.image - An array of images.
 * @param {any} props.video - An optional video.
 * @returns {JSX.Element} The rendered component.
 */
function DisplayImage({ image, video }: { image: any; video: any }) {
  const imageURL = useGetSettingValue('IMAGE_URL')
  const images = [...image, video]

  const { width } = useWindowDimensions()
  const imageSizeUnit = (width >= 768) ? (width / 18) : (width / 12)
  const imageWidth = imageSizeUnit * 2
  const imageHeight = imageSizeUnit * 3

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeIndex = (newIndex: number) => {
    setSelectedImageIndex(newIndex);
  };

  return (
    <div className="m-auto">
  {images && (
    <div className="flex flex-row gap-x-2 justify-center mt-5 mb-5">
      <CollageImage
        height={imageHeight}
        width={imageWidth}
        images={images}
        index={0}
        onClick={() => openModal(0)}
      />
      <CollageImage
        height={imageHeight}
        width={imageWidth}
        images={images}
        index={1}
        onClick={() => openModal(1)}
      />
      <div className="flex flex-col gap-y-2">
        <CollageImage
          height={imageWidth * 0.73}
          width={imageHeight * 0.73}
          images={images}
          index={2}
          onClick={() => openModal(2)}
        />
        <CollageImage
          height={imageWidth * 0.73}
          width={imageHeight * 0.73}
          images={images}
          index={3}
          onClick={() => openModal(3)}
        />
      </div>

      <CollageImage
        height={imageHeight}
        width={imageWidth}
        images={images}
        index={4}
        onClick={() => openModal(4)}
      />

      {video && (
        <div style={{ height: imageHeight, width: imageWidth }} className="rounded-md md:rounded-lg overflow-hidden">
          <video controls className="w-full h-full object-cover">
            <source data-testid={`video-${video}`} src={`${imageURL}/${video}`} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  )}

  {isModalOpen && (
    <ImageFullscreenModal
      isOpen={isModalOpen}
      onClose={closeModal}
      images={images}
      currentIndex={selectedImageIndex}
      onChangeIndex={handleChangeIndex}
    />
  )}
</div>

  )
}


/**
 * ViewEvent is a component that displays an event page.
 *
 * It renders the event's title, description, images and/or video, and reviews.
 *
 * It also renders the host's name and avatar, and a list of upcoming events by the same host.
 *
 * The component uses the image URL from the settings.
 *
 * @returns {JSX.Element} The rendered event page.
 */
function ViewEvent() {
  const { t } = useTranslation()
  const { id } = useParams()
  const currentUser = useAppSelector(getCurrentUser)
  const { meetingDetails, eventViewRefetch, eventViewRefetching } = useViewEventHook(id ? id : '')

  useEffect(() => {
    eventViewRefetch()
    if (meetingDetails) {
      trackEvent(`Events`, 'View', `${meetingDetails?.videoType} Event`);
    }
  }, [])

  if (!meetingDetails || eventViewRefetching) {
    return <Loading />
  }

  return (
    <PageTitle subTitle={meetingDetails?.title}>
      <div className="mx-auto max-w-70 mb-3">
        <div className='flex flex-col gap-y-1 mb-5'>
          <div className='font-bold text-2xl text-clip overflow-hidden hover:break-words' data-testid="event_title" id="event_title">
            {meetingDetails?.title && meetingDetails?.title.charAt(0).toUpperCase() + meetingDetails?.title.slice(1)}
          </div>

          <DisplayReviewAverageAndCount meetingDetailsId={meetingDetails.id} />

          <div className='flex flex-row items-center justify-between'>
            {meetingDetails?.videoType === 'Audio' && (
              <div data-testid={`event_${meetingDetails?.videoType}`} className="flex flex-row items-center gap-x-1">
                <Icon icon="SOUND_ON_BLACK" size="small" />
                <span id={meetingDetails?.videoType.split(' ').join('')} className='text-md font-normal'>{t('audioEvent')}</span>
              </div>
            )}
            {meetingDetails?.videoType === 'Audio And Video' && (
              <div data-testid={`event_${meetingDetails?.videoType}`} className="flex flex-row items-center gap-x-1">
                <Icon icon="VIDEO_BLACK" size="small" />
                <span id={meetingDetails?.videoType.split(' ').join('')} className='text-md font-normal'>{t('videoEvent')}</span>
              </div>
            )}
            {/* Get link from db */}
            <Share
              shareData={meetingDetails.title}
              hostName={DisplayNameAsString({ user: meetingDetails?.host, hostAnonymous: meetingDetails?.isAnonymous })}
              sharingType="shareEvent"
              meetingDetailsId={meetingDetails.id}
            />
          </div>
        </div>
        <hr />
        {/* Event images and Video */}
        {(meetingDetails?.image.length !== null && meetingDetails?.video !== null) &&
          <div className='flex m-0'>
            <DisplayImage
              key={meetingDetails?.id}
              image={meetingDetails?.image}
              video={meetingDetails?.video}
            />
          </div>
        }
        {meetingDetails?.image.length === 0 && meetingDetails?.video === null ? '' : <hr className='my-3' />}

        <div className={`flex flex-col-reverse sm:flex-row gap-x-5 md:flex-row mt-2 `}>
          <div className="w-full sm:w-8/12 md:w-8/12">
            <div className="flex flex-wrap items-center gap-1 mb-4">
              {meetingDetails?.categories?.map(category => (
                <Chip
                  value={t(`meetingCategories.${category}`) !== `meetingCategories.${category}` ? t(`meetingCategories.${category}`) : category}
                  key={category}
                  addCatId={`category-${category.replace(/ /g, '')}`}
                />
              ))}
            </div>
            <div className='flex flex-row items-center gap-x-2'>
              <Avatar
                className='p-2'
                size="xx-small"
                images={meetingDetails?.host?.image}
                avatar={meetingDetails?.host.avatar}
                anonymous={meetingDetails?.isAnonymous}
              />
              <div>
                {currentUser.id === meetingDetails.host.id ? (
                  <span
                    className="font-medium text-[1.3rem]"
                    data-testid='txt_hostedBy'>
                    {t('hostedBy')}&nbsp;
                  </span>
                ) : (
                  <span
                    className="font-medium text-[1.3rem]"
                    data-testid='txt_meet'>
                    {t('meet')}&nbsp;
                  </span>
                )}

                <span data-testid='txt_hostName' className="font-medium text-[1.3rem] capitalize">
                  {meetingDetails?.isAnonymous ? meetingDetails.host.nickName : `${meetingDetails?.host.firstName} ${meetingDetails?.host.lastName.length > 14 ? meetingDetails?.host.lastName.substring(0, 14) + '...' : meetingDetails?.host.lastName}`}
                </span>
              </div>
            </div>

            <DisplayDiscription meetingDetails={meetingDetails} />

            {meetingDetails?.help[0] !== undefined && (
              <>
                <div id="chk_help" className="mt-5">
                  <p className="font-semibold text-[1.1rem]">
                    {t('whatYouNeedToPrepare?')}
                  </p>
                  {React.Children.toArray(meetingDetails?.help.filter(h => h.trim() !== '').map((helpItem: string, index: number) => (
                    <ul className='ml-5' key={index.toString()}>
                      <li data-testid={`help-${helpItem.replace(/ /g, '')}`} className='text-sm font-normal list-disc'>{helpItem}</li>
                    </ul>
                  )))}
                </div>
                <div className='mt-5'>
                  <hr />
                </div>
              </>
            )}

            <ReviewsViewCard meetingDetailsId={meetingDetails.id} />
            <CancellationDetails meetingDetails={meetingDetails} />
          </div>

          {eventViewRefetching === false && (
            <div className="flex flex-col gap-y-2 max-h-screen lg:w-1/2 xl:w-1/2 sm:w-full md:w-full justify-start items-center md:items-start mt-2 mb-4">
              <UpcomingEvents meetingDetails={meetingDetails} />
              <RFMHostDetails hostDetails={meetingDetails} />
            </div>
          )}

        </div>
      </div>
    </PageTitle>
  )
}

  /**
   * Displays the description of the event.
   *
   * If the description is more than 200 characters, it displays a read more/less button.
   * The button toggles the full text of the description.
   * @param {{ meetingDetails: any }} props - The meeting details object.
   * @returns {JSX.Element} The component.
   */
function DisplayDiscription({ meetingDetails }: { meetingDetails: any }) {
  const { t } = useTranslation()
  const [readMoreToLess, setReadMoreToLess] = useState<boolean>(true);
  const [reviewLength, setReviewLength] = useState<number>(200);

  return (
    <>
      {meetingDetails?.description.length > 200 &&
        <>
          {readMoreToLess && <>{<div className="break-words text-sm font-normal mt-1 text-justify list-inherited" id="input_eventDetails" data-testid="event_description" dangerouslySetInnerHTML={{ __html: meetingDetails?.description.slice(0, reviewLength) || '' }} ></div>} <span className='text-xs font-normal flex justify-end underline uppercase underline-offset-1 cursor-pointer w-100 text-right' onClick={() => { setReviewLength(meetingDetails?.description?.length); setReadMoreToLess(!readMoreToLess) }} id="read_more" data-testid="read_more">{t('readMore') + "+"}</span></>}
          {!readMoreToLess && <>{<div className="break-words text-sm font-normal mt-1 text-justify list-inherited" id="input_eventDetails" data-testid="event_description" dangerouslySetInnerHTML={{ __html: meetingDetails?.description.slice(0, reviewLength) || '' }} ></div>} <span className='text-xs font-normal flex justify-end underline uppercase underline-offset-1 cursor-pointer w-100 text-right' onClick={() => { setReviewLength(200); setReadMoreToLess(!readMoreToLess) }} id="read_less" data-testid="read_less">{t('readLess') + "+"}</span></>}
          <div className='mt-5'>
            <hr />
          </div>
        </>
      }
      {meetingDetails?.description?.length < 200 &&
        <>
          <div className="break-words text-sm font-normal mt-1 text-justify list-inherited" id="input_eventDetails" data-testid="event_description" dangerouslySetInnerHTML={{ __html: meetingDetails?.description || '' }} ></div>
          <div className='mt-5'>
            <hr />
          </div>
        </>}
      {meetingDetails?.description === null && <div className='mt-5'>
        <hr />
      </div>}
    </>
  )
}

  /**
   * A component to display the average and count of feedbacks for a given meeting.
   * It queries the feedback API to fetch the average and count of feedbacks for the given meeting ID.
   * It renders a row with the average (in stars) and the count of feedbacks.
   * @param {{ meetingDetailsId: string }} props - The component props.
   * @returns {JSX.Element} The component.
   */
function DisplayReviewAverageAndCount({ meetingDetailsId }: { meetingDetailsId: string }) {

  const { data: feedbackData } = useGetFeedbackCountAndAverageQuery({ meetingDetailsId: meetingDetailsId })


  return (
    <div className='flex flex-row items-center gap-x-1 gap-y-1 text-gray-600'>
      <NewIcon icon='STAR' size='x-small' bgColor='#F6851F' stroke='#F6851F' />
      <span data-testid="ttl_feedbackAvg" className='text-sm font-normal'>{+feedbackData?.average || ''}</span>
      {feedbackData && <span data-testid="ttl_feedbackCount" className='text-sm font-normal'>({feedbackData?.count})</span>}
    </div>
  )
}

export default ViewEvent
